import React, {useEffect, useState} from "react";
import { useNavigate, useParams } from 'react-router-dom';

import '../../../../css/Admin/RequestDetailPage.css';

import AdminService from "../../../../services/AdminService";

import PaymentProcessingCard from "./Cards/PaymentProcessingCard";
import LoanAndCreditCard from "./Cards/LoanAndCreditCard";
import InsuranceCard from "./Cards/InsuranceCard";
import DocumentsCard from "./Cards/DocumentsCard";
import OffersCard from "./Cards/OffersCard";
import CustomerInfoCard from './Cards/CustomerInfoCard';
import PEOMultiFuncCard from "./Cards/PEOMultiFuncCard";

const RequestDetailPage = () => {
    
    let navigate = useNavigate();
    let { id } = useParams(); 

    const [requestDetail, setRequestDetail] = useState({});
    
    // call api to get requests
    const refresh = () => {
        AdminService.getRequestById(id)
            .then(res => {
                // state to control waiting on fullfilled request
                const quotesCategory = res?.data?.quotesCategory;
                const customerInfo = {
                        userId: res?.data?.userId,
                        firstName: res?.data?.userFirstName,
                        lastName: res?.data?.userLastName,
                        jobTitle: res?.data?.userJobTitle,
                        email: res?.data?.userEmail,
                        phone: res?.data?.userPhone,
                        company: res?.data?.requesterCompany
                }
                const quotesOffers = res?.data?.quoteOfferSummaryByRequestList;
                const serviceProviders = res?.data?.serviceProviders?.map(provider => {
                    return {
                        value: provider?.id,
                        label: provider?.businessName
                    }
                });

                const providerBlockList = res?.data?.providerBlockList?.map(provider => {
                    return {
                        value: provider?.id,
                        label: provider?.businessName
                    }
                }) || [];

                if (quotesCategory === 'PAYMENT_PROCESSING') {
                    setRequestDetail({
                        customerInfo, 
                        quotesCategory, 
                        quotesOffers, 
                        submittedDateTime: new Date(res?.data?.requestDateTime + 'Z'), // convert UTC ISO string to local Date Time
                        expirationDateTime: new Date(res?.data?.expirationDateTime + 'Z'),
                        docLinks: [res?.data?.docDownloadLink], 
                        redactedDocDownloadLink: res?.data?.redactedDocDownloadLink, 
                        notes: res?.data?.notes,
                        serviceProviders,
                        providerBlockList,
                        domainData: res?.data?.paymentProcessingData,
                    });
                } else if (quotesCategory === 'PEO') {
                    const docLinkList = [];
                    if (res?.data?.docDownloadLink) {
                        docLinkList.push(res?.data?.docDownloadLink);
                    }
                    if (res?.data?.peoData?.benefitsData?.benefitsDisclosureDoc) {
                        docLinkList.push(res?.data?.peoData?.benefitsData?.benefitsDisclosureDoc);
                    }
                    if (res?.data?.peoData?.benefitsData?.lastBenefitInvoice) {
                        docLinkList.push(res?.data?.peoData?.benefitsData?.lastBenefitInvoice);
                    }
                    if (res?.data?.peoData?.benefitsData?.census) {
                        docLinkList.push(res?.data?.peoData?.benefitsData?.census);
                    }

                    if (res?.data?.peoData?.retirementPlanData?.feeDisclosureDoc401k) {
                        docLinkList.push(res?.data?.peoData?.retirementPlanData?.feeDisclosureDoc401k);
                    }
                    if (res?.data?.peoData?.retirementPlanData?.statementTotalAssets) {
                        docLinkList.push(res?.data?.peoData?.retirementPlanData?.statementTotalAssets);
                    }
                    if (res?.data?.peoData?.retirementPlanData?.adoptionAgreement) {
                        docLinkList.push(res?.data?.peoData?.retirementPlanData?.adoptionAgreement);
                    }
                    if (res?.data?.peoData?.retirementPlanData?.invoiceDoc401k) {
                        docLinkList.push(res?.data?.peoData?.retirementPlanData?.invoiceDoc401k);
                    }
                    setRequestDetail({
                        customerInfo, 
                        quotesCategory, 
                        quotesOffers, 
                        submittedDateTime: new Date(res?.data?.requestDateTime + 'Z'),
                        expirationDateTime: new Date(res?.data?.expirationDateTime + 'Z'),
                        docLinks: docLinkList,
                        redactedDocDownloadLink: res?.data?.redactedDocDownloadLink, 
                        notes: res?.data?.notes,
                        serviceProviders,
                        providerBlockList,
                        domainData: res?.data?.peoData
                    });  
                } else if (quotesCategory === 'PAYROLL') {
                    setRequestDetail({
                        customerInfo, 
                        quotesCategory, 
                        quotesOffers, 
                        submittedDateTime: new Date(res?.data?.requestDateTime + 'Z'),
                        expirationDateTime: new Date(res?.data?.expirationDateTime + 'Z'),
                        docLinks: [res?.data?.docDownloadLink], 
                        redactedDocDownloadLink: res?.data?.redactedDocDownloadLink,
                        notes: res?.data?.notes,
                        serviceProviders,
                        providerBlockList,
                        domainData: res?.data?.payrollData
                    });
                } else if (quotesCategory === 'BENEFITS') {
                    const docLinkList = [];
                    if (res?.data?.benefitsData?.benefitsDisclosureDoc) {
                        docLinkList.push(res?.data?.benefitsData?.benefitsDisclosureDoc);
                    }
                    if (res?.data?.benefitsData?.lastBenefitInvoice) {
                        docLinkList.push(res?.data?.benefitsData?.lastBenefitInvoice);
                    }
                    if (res?.data?.benefitsData?.census) {
                        docLinkList.push(res?.data?.benefitsData?.census);
                    }

                    setRequestDetail({
                        customerInfo, 
                        quotesCategory, 
                        quotesOffers, 
                        submittedDateTime: new Date(res?.data?.requestDateTime + 'Z'),
                        expirationDateTime: new Date(res?.data?.expirationDateTime + 'Z'),
                        docLinks: docLinkList, 
                        redactedDocDownloadLink: res?.data?.redactedDocDownloadLink,
                        notes: res?.data?.notes,
                        serviceProviders,
                        providerBlockList,
                        domainData: res?.data?.benefitsData
                    });
                } else if (quotesCategory === 'RETIREMENT_PLAN') {
                    const docLinkList = [];
                    if (res?.data?.retirementPlanData?.feeDisclosureDoc401k) {
                        docLinkList.push(res?.data?.retirementPlanData?.feeDisclosureDoc401k);
                    }
                    if (res?.data?.retirementPlanData?.statementTotalAssets) {
                        docLinkList.push(res?.data?.retirementPlanData?.statementTotalAssets);
                    }
                    if (res?.data?.retirementPlanData?.adoptionAgreement) {
                        docLinkList.push(res?.data?.retirementPlanData?.adoptionAgreement);
                    }
                    if (res?.data?.retirementPlanData?.invoiceDoc401k) {
                        docLinkList.push(res?.data?.retirementPlanData?.invoiceDoc401k);
                    }

                    setRequestDetail({
                        customerInfo, 
                        quotesCategory, 
                        quotesOffers, 
                        submittedDateTime: new Date(res?.data?.requestDateTime + 'Z'),
                        expirationDateTime: new Date(res?.data?.expirationDateTime + 'Z'),
                        docLinks: docLinkList, 
                        redactedDocDownloadLink: res?.data?.redactedDocDownloadLink,
                        notes: res?.data?.notes,
                        serviceProviders,
                        providerBlockList,
                        domainData: res?.data?.retirementPlanData
                    });
                } else if (quotesCategory === 'LOAN' || quotesCategory === 'CREDIT_LINE') {
                    setRequestDetail({
                        customerInfo, 
                        quotesCategory, quotesOffers, 
                        submittedDateTime: new Date(res?.data?.requestDateTime + 'Z'),
                        expirationDateTime: new Date(res?.data?.expirationDateTime + 'Z'),
                        docLinks: [res?.data?.docDownloadLink], 
                        redactedDocDownloadLink: res?.data?.redactedDocDownloadLink, 
                        notes: res?.data?.notes,
                        serviceProviders,
                        providerBlockList,
                        domainData: res?.data?.loanAndCreditData
                    });
                } else if (quotesCategory === 'INSURANCE') {
                    setRequestDetail({
                        customerInfo, 
                        quotesCategory, 
                        quotesOffers, 
                        submittedDateTime: new Date(res?.data?.requestDateTime + 'Z'),
                        expirationDateTime: new Date(res?.data?.expirationDateTime + 'Z'),
                        docLinks: [res?.data?.docDownloadLink], 
                        redactedDocDownloadLink: res?.data?.redactedDocDownloadLink,
                        notes: res?.data?.notes,
                        serviceProviders,
                        providerBlockList,
                        domainData: res?.data?.insuranceData
                    });
                }

            }).catch(e => console.log(e))
    }
    
    // on refresh get requets info from api
    useEffect(refresh, [])
    
    const handleRequestDomainDataSave = (domainData, redactedDoc, expirationTime, category, selectedBlockedProviders) => {
        const requestObj = {
            'quotesCategory': category,
            'redactedDocDownloadLink': redactedDoc,
            'expiredDateTime': expirationTime.toISOString(), // convert a local Date Ojbect to ISO String in UTC as backend stores in UTC,
            'selectedBlockedProviderIds': selectedBlockedProviders?.length > 0 ? selectedBlockedProviders?.map(provider => provider.value) : []
        }
        
        if (category === 'PAYMENT_PROCESSING') {
            domainData.totalVolume = Math.round(domainData.totalVolume);
            requestObj.paymentProcessingData = domainData;
        } else if (category === 'PAYROLL') {
            requestObj.payrollData = domainData;
        } else if (category === 'PEO') {
            requestObj.peoData = {
                'isBenefitsSelected': domainData.isBenefitsSelected,
                'isRetirementPlanSelected': domainData.isRetirementPlanSelected,
                'renewalDate': domainData.renewalDate,
                'totalFeeMonthly': domainData.totalFeeMonthly
            };

            requestObj.payrollData = domainData.payrollData;
            delete requestObj.peoData.payrollData;

            requestObj.benefitsData = domainData.benefitsData;
            delete requestObj.peoData.benefitsData;

            requestObj.retirementPlanData = domainData.retirementPlanData;  
            delete requestObj.peoData.retirementPlanData;
        } else if (category === 'BENEFITS') {
            requestObj.benefitsData = domainData;
        } else if (category === 'RETIREMENT_PLAN') {
            requestObj.retirementPlanData = domainData;
        } else if (category === 'LOAN' || category === 'CREDIT_LINE') {
            requestObj.loanAndCreditData = domainData;
        } else if (category === 'INSURANCE') {
            requestObj.insuranceData = domainData;
        }

        AdminService.updateRequestById(id, requestObj)
            .then(res => { alert('Saved!') })
            .catch(e => console.log(e));
    }

    const handleCloseBtnClick = (e) => {
        e.preventDefault();
        navigate(`/admin/requests`);
    }

    const handleMoveAsNewBtnClick = (e) => {
        e.preventDefault();
        const requestObj = { 
            'isFlagged': false,
            'isVerified': false
        };

        AdminService.updateRequestById(id, requestObj)
            .then(res => { alert('Move back to new request!') })
            .catch(e => console.log(e));
    }

    const handleSaveAsFlaggedbtnClick = (e) => {
        e.preventDefault();
        const requestObj = { 
            'isFlagged': true,
            'isVerified': false
        };

        AdminService.updateRequestById(id, requestObj)
            .then(res => { alert('Flagged!') })
            .catch(e => console.log(e));
    }

    const handlePublishBtnClick = (e) => {
        e.preventDefault();
        const requestObj = {
            'isFlagged': false,
            'isVerified': true 
        };

        AdminService.updateRequestById(id, requestObj)
            .then(res => { alert('Published!') })
            .catch(e => console.log(e));
    }

    const handleUpdateRequestOwnerBtnClick = (userId) => {
        const requestObj = {
            'userId': userId
        };

        AdminService.updateRequestById(id, requestObj)
            .then(res => { 
                alert('Request owner updated!!');
                refresh();
            })
            .catch(e => {
                console.log(e)
                alert('Failed to update. New user id might be invalid or something wrong with our server!');
            });
    }

    const handleSaveNotesBtnClick = (e) => {
        e.preventDefault();
        const requestObj = {
            'notes': requestDetail.notes
        }

        AdminService.updateRequestById(id, requestObj)
            .then(res => { alert('Notes saved!') })
            .catch(e => console.log(e));
    }

    const handleDeleteRequestBtnClick = (e) => {
        e.preventDefault();

        const confirmDelete = window.confirm("Are you sure you want to delete this request? It will delete corresponding quotes offers as well.");
        if (confirmDelete) {

            AdminService.deleteRequestById(id)
            .then(res => {
                console.log(res);
                alert('Deleted!');
                navigate(`/admin/requests`);
            })
            .catch(e => {
                console.log(e)
                alert('Something went wrong. Unable to delete.');
            });
          }
    }

    const renderRequestCard = () => {
        if (requestDetail?.domainData && requestDetail?.docLinks) {
            if (requestDetail?.quotesCategory === 'PAYMENT_PROCESSING') {
                return (<PaymentProcessingCard
                            domainData={requestDetail.domainData}
                            submittedDateTime={requestDetail.submittedDateTime}
                            expirationDateTime={requestDetail.expirationDateTime}
                            redactedDocLink={requestDetail.redactedDocDownloadLink}
                            handleSaveBtnClick={handleRequestDomainDataSave}
                            providerBlockList={requestDetail.providerBlockList}
                            serviceProviders={requestDetail.serviceProviders}
                />)
            } else if (requestDetail?.quotesCategory === 'PAYROLL' || 
                requestDetail?.quotesCategory === 'PEO' ||
                requestDetail?.quotesCategory === 'BENEFITS' ||
                requestDetail?.quotesCategory === 'RETIREMENT_PLAN'
             ) {
                return (<PEOMultiFuncCard
                            quotesCategory={requestDetail.quotesCategory}
                            domainData={requestDetail.domainData} 
                            submittedDateTime={requestDetail.submittedDateTime}
                            expirationDateTime={requestDetail.expirationDateTime}
                            redactedDocLink={requestDetail.redactedDocDownloadLink}
                            handleSaveBtnClick={handleRequestDomainDataSave}
                            providerBlockList={requestDetail.providerBlockList}
                            serviceProviders={requestDetail.serviceProviders}
                />)                
            } else if (requestDetail?.quotesCategory === 'LOAN' || requestDetail?.quotesCategory === 'CREDIT_LINE') {
                return (<LoanAndCreditCard
                            domainData={requestDetail.domainData} 
                            submittedDateTime={requestDetail.submittedDateTime}
                            expirationDateTime={requestDetail.expirationDateTime}
                            redactedDocLink={requestDetail.redactedDocDownloadLink}
                            handleSaveBtnClick={handleRequestDomainDataSave}
                            providerBlockList={requestDetail.providerBlockList}
                            serviceProviders={requestDetail.serviceProviders}
                />)                
            } else if (requestDetail?.quotesCategory === 'INSURANCE') {
                return (<InsuranceCard
                            domainData={requestDetail.domainData} 
                            submittedDateTime={requestDetail.submittedDateTime}
                            expirationDateTime={requestDetail.expirationDateTime}
                            redactedDocLink={requestDetail.redactedDocDownloadLink}
                            handleSaveBtnClick={handleRequestDomainDataSave}
                            providerBlockList={requestDetail.providerBlockList}
                            serviceProviders={requestDetail.serviceProviders}
                />)                
            }
        }
    }

    return (
        <section className="request-detail-section">
            <div className="request-detail-subnav-ctnr">
                <div>
                    <div className="request-detail-subnav-meta-info">
                        Request ID: {id}
                    </div>
                    <div className="request-detail-subnav-business-name">
                        Business Name
                    </div>
                </div>
                <div className="request-detail-subnav-btn-ctnr">
                    <button className="request-detail-subnav-close-btn" onClick={handleCloseBtnClick}> Close </button>
                    <button className="request-detail-subnav-save-as-flagged-btn" onClick={handleMoveAsNewBtnClick}> Move as New </button>
                    <button className="request-detail-subnav-save-as-flagged-btn" onClick={handleSaveAsFlaggedbtnClick}> Save as Flagged</button>
                    <button className="request-detail-subnav-publish-btn" onClick={handlePublishBtnClick}> Publish </button>
                </div>
            </div>
            <div className="request-detail-main-ctnr">
                { requestDetail?.customerInfo && <CustomerInfoCard customerInfo={requestDetail.customerInfo} handleUpdateRequestOwnerBtnClick={handleUpdateRequestOwnerBtnClick}/> }
                <div className="request-detail-customer-cards-ctnr">
                    { renderRequestCard() }
                    { requestDetail.docLinks && <DocumentsCard docLinks={requestDetail.docLinks}/>}
                    { requestDetail.quotesOffers && <OffersCard category={requestDetail.quotesCategory} quotes={requestDetail.quotesOffers}/>}
                    {
                    <div className="request-detail-notes-section">
                        <div> Internal Notes </div>
                        <textarea className="request-detail-notes-textarea" 
                            value={requestDetail.notes} 
                            onChange={(e) => {setRequestDetail({ ...requestDetail, notes: e.target.value })}}
                        ></textarea>
                        <div className="request-detail-save-notes-btn-ctn">
                            <button onClick={handleSaveNotesBtnClick}> save notes </button>
                        </div>
                    </div>
                    }
                    <button onClick={handleDeleteRequestBtnClick}> delete this request </button>
                </div>
            </div>
        </section>
    )
}

export default RequestDetailPage;