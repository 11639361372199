import React, {useEffect, useState} from "react";

import '../../../../../css/Admin/Cards/CustomerInfoCard.css';

const CustomerInfoCard = ({customerInfo, handleUpdateRequestOwnerBtnClick}) => {

    const [newUserId, setNewUserId] = useState();

    return (
        <section className="request-detail-customer-info-ctnr">
            <div className="request-detail-customer-info-header"> Customer </div>
            <div className="request-detail-customer-info-row"> 
                <div className="request-detail-customer-info-row-title"> User ID </div>
                <div> { customerInfo.userId }</div>
            </div>
            <div className="request-detail-customer-info-row"> 
                <div className="request-detail-customer-info-row-title"> Name </div>
                <div> { customerInfo.firstName + ' ' + customerInfo.lastName }</div>
            </div>
            <div className="request-detail-customer-info-row">
                <div className="request-detail-customer-info-row-title"> Contact </div>
                <div> { customerInfo.email }</div>
                <div> { customerInfo.phone } </div>
            </div>
            <div className="request-detail-customer-info-row">
                <div className="request-detail-customer-info-row-title"> Role </div>
                <div> { customerInfo.jobTitle } </div>
            </div>
            <div className="request-detail-customer-info-row">
                <div className="request-detail-customer-info-row-title"> Year In Business Or Since </div>
                <div> { customerInfo.company.establishedYear } </div>
            </div>
            <div className="request-detail-customer-info-row">
                <div className="request-detail-customer-info-row-title"> Annual Revenue </div>
                <div> { customerInfo.company.annualRevenue } </div>
            </div>
            <div className="request-detail-customer-info-row">
                <div className="request-detail-customer-info-row-title"> Employee </div>
                <div> { customerInfo.company.employeeCounts }</div>
            </div>
            <div className="request-detail-customer-info-row">
                <label htmlFor="new_user_id" className="request-detail-customer-info-row-title"> New owner (UID) </label>
                <input 
                    id="insurance-coverage-amount" 
                    type="number" 
                    onChange={(e) =>{setNewUserId(e.target.value)}}
                />
            </div>
            <div className="request-detail-customer-info-update-owner-btn">
                <button onClick={(e) => {handleUpdateRequestOwnerBtnClick(newUserId)}}> update </button>
            </div>
        </section>
    )
}

export default CustomerInfoCard;